import React, { Suspense, lazy } from "react";
import "./assets/css/style.css"
import "./assets/css/responsive.css"
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import { Provider } from "react-redux";
import store from "./store";
import logo from "./assets/logo.png"

const AppRouter = lazy(() => import('./routes'))
const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div style={{ textAlign: "center", minHeight: '100vh', display: 'grid', placeItems: 'center' }}>
        <div className="d-flex flex-column">
          <img src={logo} alt="logo" height={100}/>
          loading...
        </div>
      </div>}>
        <AppRouter />
      </Suspense>
    </Provider>
  )
}
export default App