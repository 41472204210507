import { IN_MEETING, MEETING_CHANNEL, MEETING_TOKEN, STREAM_USERS, MEETING_DATA } from "../action/types";

const INIT_STATE = {
    meetingChannel: null,
    meetingToken: null,
    stream_users: [],
    in_meeting: false,
    meetingData: null
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case MEETING_CHANNEL:
            return {
                ...state,
                meetingChannel: payload,
            };
        case MEETING_TOKEN:
            return {
                ...state,
                meetingToken: payload,
            };
        case STREAM_USERS:
            return {
                ...state,
                stream_users: payload,
            };
        case IN_MEETING:
            return {
                ...state,
                in_meeting: payload,
            };
        case MEETING_DATA:
            return {
                ...state,
                meetingData: payload,
            };
        default:
            return state;
    }
};